import propTypes from 'prop-types';
import { useApi } from '../context/apiContext';
import { Icon } from '@iconify/react';
import { RatingStar } from './RatingStar';
import RatingBarsComponent from './RatingBarsComponent';
import { Fragment, useState, useEffect } from "react";
import { createRecipeReview, getRecipeReviews } from '../api/reviewsApi';
import StaticRatingStar from './StaticRatingStar';
import { useForm } from '../hooks/useForm';
import { useAuth } from '../authContext';
import ModalComentario from './ModalComentario';

const ReviewComponent = ({objectType, objectReviewId}) => {
    const [comments, setComments] = useState([]);
    const [sending, setSending] = useState(false);
    const [ showMsg, setShowMsg ] = useState(false);
    const { getTextByKey, currentLocale } = useApi();
    const {name, email, comment, rating, onInputChange, formState} = useForm({name: '', email: '', comment: '', rating: 0}) ;
    const [validation, setValidation] = useState({name: true, email: true, comment: true, rating: true}) ;
    const { currentUser } = useAuth();

    useEffect(() => {
        switch (objectType) {
            case 'recipe':
                getRecipeReviews(objectReviewId).then(data => {
                    setComments(data.list)
                })
                break;
            case 'product':
                
                break;
        
            default:
                throw new Error(`ReviewComponent doesn't use ${objectType}`);
        }
    }, [objectReviewId, objectType])

    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalidData()) {
            console.error('error');
            return;
        }

        switch (objectType) {
            case 'recipe':
                setSending(true);
                createRecipeReview(
                    {
                        recipeId: objectReviewId, 
                        name, 
                        email,
                        comment,
                        score: rating,
                        userUid: currentUser ? currentUser.uid : ''
                    }
                ).then(data => {
                    setShowMsg(true);
                    setSending(false);
                })
                break;
            case 'product':
                
                break;
        
            default:
                throw new Error(`ReviewComponent doesn't use ${objectType}`);
        }
    }

    const isInvalidData = () => {
        if (![1,2,3,4,5].includes(rating)) {
            changeValidation('rating', false);
        } else {
            changeValidation('rating', true);
        }

        if (comment.length <= 1) {
            changeValidation('comment', false);
        } else {
            changeValidation('comment', true);
        }

        for(const v in validation) {
            if (!validation[v]) {
                return true;
            }
        }
        return false;
    }

    const changeValidation = (validatorName, state) => {
        setValidation((v) => ({...v, [validatorName]: state}));
    }
    
    return (
        <section className='reviews gray-1-color my-lg-3 my-0 mt-lg-5 mt-3'>
                <div className='container pt-lg-5 pt-3'>
                    <h3 className='primary-color text-center carrefour-sans-bold mb-lg-5 mb-3'>{getTextByKey('resenas')}</h3>
                    <div className="row py-2">
                    <RatingBarsComponent ratings={comments.map(comment => comment.score)} />
                    <div className="col-12 offset-md-1 col-md-4 offset-xl-2 col-xl-3 my-3 my-lg-0 pt-5">
                        <div className="order-by px-2 pt-3">
                            <label htmlFor="select-sort-by" className='d-block w-100'>Ordenar por:</label>
                            <select name="" id="select-sort-by" className='border-0 border-bottom d-block w-100'>
                                <option defaultValue="">Más relevantes</option>
                                <option defaultValue="">Otra opción</option>
                            </select>
                        </div>
                    </div>
                    </div>
                    <hr className='hr-color-1' />
                    <div className='the-reviews'>
                        {
                            comments.map(comment => (
                                <div key={comment.id} className='singular-review py-2 my-3'>
                                    <p className='mb-0 gray-1-color'>Por {comment.name}</p>
                                        <StaticRatingStar rating={comment.score} />
                                    <p className='mt-2 gray-2-color'>{comment.comment}</p>
                                    <hr className='hr-color-1' />
                                </div>
                            ))
                        }
                    </div>
                    <div className='p-2'>
                        <h4 className='source-sans-semibold mb-4'>{getTextByKey('escribe-resena')}</h4>
                        <form onSubmit={onSubmit} action="" className='p-lg-0 p-2'>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-3 mb-4">
                                    <div className="form-group">
                                    <label htmlFor="input-name" className='d-block w-100'>{getTextByKey('nombres')}</label>
                                    <input type="text" id='input-name' placeholder={getTextByKey('nombres_form_placeholder')} name='name' onChange={onInputChange} value={name} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3 mb-4">
                                    <div className="form-group">
                                    <label htmlFor="input-email" className='d-block w-100'>{getTextByKey('correo_electronico_form')}</label>
                                    <input type="email" id='input-email' name='email' value={email} onChange={onInputChange} placeholder={getTextByKey('correo_electronico_form_placeholder')} />
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <p className='mb-0'>Calificar</p>
                                    <RatingStar onChange={(rating) => onInputChange({target: {name: 'rating', value: rating}})} />
                                    {
                                        !validation.rating && <div className='error-msg'>{getTextByKey('seleccione-puntuacion')}</div>
                                    }
                                </div>
                                <textarea name='comment' value={comment} onChange={onInputChange}></textarea>
                                {
                                    !validation.rating && <div className='error-msg'>Por favor escriba un comentario</div>
                                }
                                <div className='text-end mt-4'>
                                    <button type='submit' className='btn-g primary-color py-2 px-5' disabled={sending}>{getTextByKey('enviar')}</button>
                                </div>
                            </div>
                        </form>
                        <ModalComentario setShowMsg={setShowMsg} showMsg={showMsg} />
                    </div>
                </div>
            </section>
  )
}

ReviewComponent.propTypes = {
    objectType: propTypes.oneOf(['recipe', 'product']).isRequired,
    objectReviewId: propTypes.number.isRequired
}

export default ReviewComponent