import React from "react";
import { ContactComponent } from "./components/ContactoComponent";
import { useApi } from "../../context/apiContext";
import { Icon } from '@iconify/react';
import { getPqrsCategories, getPqrsCausas, getPqrsNovedades, getPqrsServicios, sendPqrs } from "../../api/pqrsApi";
import { useForm } from "../../hooks/useForm";
import ModalPqrsMandado from "../../components/ModalPqrsMandado";
import { useEffect } from "react";

const initialState = {
  juridico: "false",
  name: '',
  surname: '',
  identification_number: '',
  business_name: '',
  NIT: '',
  address: '',
  city: '',
  type: 1,
  otrotype:'',
  servicio:0,
  otroservicio:'',
  novedad:0,
  causa:0,
  otracausa:'',
  producto:'',
  lote:'',
  department: '',
  email: '',
  telephone: '',
  comment: '',
  file: '',
  nameFile: '',
  autorizoDatos: false,
}


export default function ContactoPage() {
  
  const { getTextByKey, getTextBigByKey, currentLocale } = useApi();  
  const [categories, setCategories] = React.useState(false);
   const [category, setCategory] = React.useState(false);
  const  {onInputChange, formState, onResetForm } = useForm(initialState);
  const [sending, setSending] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [modalText, setModalText] = React.useState("");
  const [ error, setError ] = React.useState(false);


 useEffect(() => {
    getPqrsCategories(currentLocale).then(data => {
      if (data) {
        if (data.length) {
          setCategories(data);
          setCategory(data[0]);
          onInputChange({target: {name:'type', value: data[0].id}})
        }
      }
    })
   
  }, []);

  const handleChangeRadio = (event, category_) =>{
    const typeId = event.target.value; 
    formState.type = typeId;
    setCategory(category_);

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    if( !formState.autorizoDatos ) {
      setModalText({
        main:"Error en el formulario",
        sub:"Debe autorizar los datos"
      });
      setError('autorizar')
      setShowModal(true);
      setSending(false);
      return;
    }
    try {
      const data = await sendPqrs(formState);
      setModalText("");
      setShowModal(true);
      setSending(false);
      onResetForm();
    } catch (error) {
      console.log(error);
      if (error.response.status != 200) {
        setModalText({
          main:getTextByKey('Información_enviada_invalida'),
          sub:getTextByKey('Información_enviada_invalida_sub')
        });
        setShowModal(true);
        setError(true);
      }
      setSending(false);
    }
  }

  if(!categories)
    return (
      <div className='container'>
          <div className="text-center-c">
            <div className="loader-box">
                <img src="/img/SVG/loader.svg" alt="" />
            </div>
          </div>
      </div>
      );

  return (
    <>
          <div className="contacto my-4">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-5 offset-md-0 col-auto offset-auto mx-auto mx-md-0 offset-lg-0">
                  <div className="primary-border-color box-contacto white-bg-color p-2">
                    <p className="primary-color source-sans-bold md-p mb-2">{getTextByKey('servicio_cliente')}</p>
                      <a target="_blank" href={`https://api.whatsapp.com/send?phone=+57${getTextByKey("numero-whatsapp")}&text=${getTextByKey('texto-adicional-whatsapp')}`} className="icon-info-banner mb-0">
                          <p className="gray-1-color source-sans-semibold mb-0"><Icon className="primary-color mb-1" icon="ic:baseline-whatsapp"/> {getTextByKey("numero-whatsapp")}</p>
                      </a>
                      <a href={`tel:${getTextByKey("numero")}`} className="icon-info-banner mb-0">
                          <p className="gray-1-color source-sans-semibold mb-0"><Icon className="primary-color mb-1" icon="fluent:call-12-regular"/> {getTextByKey('numero')}</p>
                      </a>
                      <a target="_blank" href={`mailto:${getTextByKey("mail")}`} className="icon-info-banner mb-0">
                          <p className="gray-1-color source-sans-semibold mb-0"><Icon className="primary-color mb-1" icon="material-symbols:mail-outline-sharp"/> {getTextByKey('mail')}</p>
                      </a>
                  </div>
              </div>
              <div className="col-lg-8 px-lg-5 px-4 pt-lg-5 pt-3">
                <div className="text-center">
                  <h2 className="primary-color source-sans-bold py-lg-3 py-2">{getTextByKey('formulario')}</h2>
                  <p className="gray-1-color" dangerouslySetInnerHTML={{ __html: getTextBigByKey("formulario_desc") }}></p>
                </div>
                <form onSubmit={handleSubmit} action="">
                  <ul className="d-flex flex-wrap my-3 p-0">
                    {
                      categories.map((category_) => (
                        <li key={category_.id} className='list-style-none mx-auto'>
                          <label className="d-flex align-items-center radio-button-container">
                              <input type="radio" name="type" onChange={(event) => handleChangeRadio(event, category_)} onInput={onInputChange} value={category_.id} checked={category_.id == formState.type} />
                              <p className='gray-1-color source-sans-bold mb-0'>{category_.name}</p>
                          </label>
                        </li>
                      ))
                    }
                  </ul>
                  <div className="info secundary-bg-color primary-border-color px-2 py-1 rounded mx-auto mb-5 sm">
                    <p>{category.information}</p> 
                  </div>
                  <ContactComponent onInput={onInputChange} formState={formState} disabled={sending}/>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ModalPqrsMandado error={error} showMsg={showModal} setShowMsg={setShowModal} text={modalText} onResetForm={onResetForm}/> 
    </> 
  )
}
